import React from "react";
import Swiper from "swiper";
import "./index.css";
import Logo from "../../assets/images/png/dev.png";
import logoDeslogado from "../../assets/images/png/logoDeslogado.png";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";
import FaqDeslogado from "../FaqDeslogado";
import { useLoad } from "../../context/Load";
import EsqueciSenha from "../EsqueciSenha";
import PrimeiroAcesso from "../PrimeiroAcesso";
import Mask from "../../services/mask";
import { validaCpf } from "../../services/validaCpf";
import { validaEmail } from "../../services/validaEmail";
import { validaSenha } from "../../services/validaSenha";
import RecriarSenha from "../RecriarSenha";
import setaDireita from "../../assets/images/svg/setaDireita.svg";
import moeda from "../../assets/images/png/moeda.png";
import todasLogo from "../../assets/images/png/logoClube.png";
import textoLogin from "../../assets/images/png/textoLogin.png";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../../globals";
export default function Login() {
  const navigate = useNavigate();
  const [login, setLogin] = useState({
    userName: "",
    password: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const { load, setLoad } = useLoad();

  async function fnLogin() {
    if (!captchaToken) {
      toast.error("Por favor, verifique o reCAPTCHA.");
      return;
    }
    localStorage.clear();
    setLoad(true);
    storage.set("captcha", captchaToken);
    try {
      let response = await ApiServices.apiLogin(
        "identidade/admin/autenticar",
        login
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        storage.set("USUARIO", data.data);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
      if (storage.get("USUARIO")?.token) {
        navigate("/home");
      } else {
        navigate("/");
      }
    }
  }
  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };


  const pathname = () => {
    return window.location.pathname;
  };

  useEffect(() => {
    pathname();
  }, [navigate]);
  return (
    <div className="d-flex">
      <div className=" bgLogin">
        {pathname().includes("/login") && (
          <div className=" centroVertical ">
            <div className="cardLogin">
              <img width={200} src={todasLogo} alt="" />

              <div className="textoLogin2">
                O benefício exclusivo
                <br />
                recheado de prêmios
                <br />
                para você!
              </div>
              <h5 className="textoLogin3">
                Insira seu login de acesso
                <br />
                ao Portal do Revendedor
              </h5>

              <form autoComplete="off">
                <label>CPF</label>
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="text"
                  value={Mask.cpf(login.userName)}
                  onChange={(e) => {
                    setLogin((prev) => ({
                      ...prev,
                      userName: Mask.number(e.target.value),
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {!validaCpf(login.userName) && login.userName.length >= 5
                    ? "Formato inválido"
                    : " "}
                </div>
              </form>

              <form autoComplete="off">
                <label className="mt-3">SENHA</label>
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="password"
                  value={login.password}
                  onChange={(e) => {
                    setLogin((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }));
                  }}
                />
                <div className="d-flex justify-content-between">
                  <div className="avisoCampo">
                    {!validaSenha(login.password) && login.password.length >= 1
                      ? "Formato inválido"
                      : " "}
                  </div>
                  <div className="" style={{ fontSize: 13 }}>
                    <div
                      className="pointer"
                      onClick={() => {
                        navigate("/esqueci-senha");
                      }}
                    >
                      ESQUECI MINHA SENHA
                    </div>
                  </div>
                </div>
              </form>
              <div className="d-flex justify-content-center">

              <ReCAPTCHA
                sitekey={globals.REACT_APP_RECAPTCHA_KEY}
                onChange={handleCaptchaChange}
                />
              </div>
              <button
                className="btn btn-primary w-100 my-3"
                onClick={fnLogin}
                disabled={
                  !validaCpf(login.userName) || !validaSenha(login.password)
                }
              >
                <div>ENTRAR</div>
              </button>
            </div>
          </div>
        )}

        {pathname().includes("/faq-deslogado") && <FaqDeslogado />}
        {pathname().includes("/esqueci-senha") && <EsqueciSenha />}
        {pathname().includes("/alterar-senha") && <RecriarSenha />}
        {pathname().includes("/primeiro-acesso") && <PrimeiroAcesso />}
      </div>
    </div>
  );
}
