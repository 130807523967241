import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";

export default function Veiculos() {
  const [listaAdministradores, setListaAdministradores] = useState([]);
  const [listaVeiculos, setListaVeiculos] = useState([]);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [pesquisar, setPesquisar] = useState("");
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "linha.nome",
      header: "Linha",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "modelo.nome",
      header: "Order HMB: Product",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "materiais",
      header: "Order HMB: Material",

      Cell: ({ row }) => (
        <div className="pointer" onClick={() => {}}>
          <select className="inputPrimary" onChange={(e) => {}}>
            {row.original.materiais.map((e, i) => (
              <option value={i} key={i}>
                {e.nome}
              </option>
            ))}
          </select>
        </div>
      ),

      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "status",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      header: "Ações",
      id: "mrt-custom-actions",
      size: 90,
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate(
              "/gestao-de-usuarios/veiculos/editar/" + row.original.veiculoId
            );
          }}
        >
          <FaRegEdit style={{ fontSize: 20, color:"#a98862" }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnExtrairVeiculos() {
    try {
      setLoad(true);
      let response = await ApiServices.apiDownloadXlsxGET(`veiculo/extrair`);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnVeiculos() {
    const parametros = {
      q: pesquisar || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `veiculo/listagem?${queryString}`
      );

      if (response.statusCode == 200) {
        setListaVeiculos(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnVeiculos();
  }, [pesquisar]);
  useEffect(() => {
    const newObject = {};

    filtroColuna.forEach((item) => {
      newObject[item.id] = item.value;
    });
    setNovoFiltroColuna(newObject);
  }, [filtroColuna]);
  useEffect(() => {
    fnVeiculos();
  }, [novoFiltroColuna]);
  return (
    <>
      <div className="row">
        <span className="tituloPagina">CADASTRO DE VEÍCULOS</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/veiculos/novo");
            }}
          >
            + | Novo veículo
          </button>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary my-2" onClick={fnExtrairVeiculos}>
            Extrair veículos
          </button>
        </div>
        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/veiculos/importacao");
            }}
          >
            Importar veículos
          </button>
        </div>
        <div className="subTituloPagina">Veículos cadastrados</div>

        <Tabela
          buscaGlobal={true}
          setPesquisar={setPesquisar}
          buscaColunas={false}
          colunas={colunas}
          dados={listaVeiculos}
          setPaginacao={setPaginacao}
          paginacao={paginacao}
          filtroColuna={filtroColuna}
          setFiltroColuna={setFiltroColuna}
        />
      </div>
    </>
  );
}
